import superagent from 'superagent';

const URL = '/release';
//create/update release request
export const create_update = (token, app_body, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ app_body: app_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true, _id: response.body._id })
            } else {
                callback({ success: false })
            }
        });
};
//get release data request
export const get = (token, query, callback) => {
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
        });
};
//delete release data request
export const delet = (token, _id, callback) => {
    superagent.delete(URL + '/' + _id)
        .set('Authorization', 'Bearer ' + token)
        .end((err, response) => {
            if (response.status == 200) {
                callback(true)
            } else {
                callback(false)
            }
            console.log(JSON.stringify(response))
        });
};
