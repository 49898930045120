import superagent from "superagent";

const URL = "/login";
//login request
export const login = (credentials, callback) => {
	superagent
		.post(URL)//post on URL
		.send(credentials)//add creds in body
		.end((err, response) => {
			console.log(response);
			//if err request failed callback null
			if (err) {
				callback(null);
				console.log("Request failed");
			} else {
				//no error check status code 200
				if (response.status === 200) {
					//login success callback body
					response.body.version = response.headers.version;
					callback(response.body);
				} else {
					//login failed
					callback(null);
				}
			}
		});
};
