import React from 'react';
import './App.css';
import Auth from "./components/auth/Auth";
import { withCookies } from "react-cookie";
import Dashboard from "./components/dashboard/Dashboard";

class App extends React.Component {
    constructor(props) {
        super(props);
        //State init
        this.state = {
            content: <Auth onComplete={this.handleOnAuth} />,
        };
        //handler init
        this.handleOnAuth = this.handleOnAuth.bind()
    }
    componentDidMount() {
        //If NODE_ENV is profuctionn disable logs
        if (process.env.NODE_ENV != 'development') {
            console.log = function () { }
        }
        //check if has token If so render Dashboard
        if (this.props.cookies.cookies.token !== undefined) {
            this.setState({ content: <Dashboard cookies={this.props.cookies} /> })
        } else {
            this.setState({ content: <Auth onComplete={this.handleOnAuth} /> })
        }
    }
    //Auth handler
    handleOnAuth = res => {
        //setting cookies
        this.props.cookies.set(
            'token',
            res.token,
            '/');
        this.props.cookies.set(
            'username',
            res.username,
            '/');
        this.props.cookies.set(
            'version',
            res.version,
            {
                path: '/',
                maxAge: 10800
            });
        //Rendering dashboard
        this.setState({ content: <Dashboard /> })
    };

    render() {
        //Render container
        return (
            <div className="App">
                {this.state.content}
            </div>
        );
    }
}
export default withCookies(App);
