import superagent from 'superagent';

const URL = '/carousel';
//create / update carousel request
export const create_update = (token, carousel_body, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ carousel_body: carousel_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true, data: response.body })
            } else {
                callback({ success: false })
            }
            //console.log(response)
        });
};
//get carousel data request
export const get = (token, query, callback) => {
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No carousel data available')
            }
            //console.log(response);
        }
        );
};
//delete app request
export const delet = (token, _id, callback) => {
    superagent.delete(URL + '/' + _id)
        .set('Authorization', 'Bearer ' + token)
        .send({ _id: _id })
        .end((err, response) => {
            if (response.status == 200) {
                callback(true)
            } else {
                callback(false)
            }
            //console.log(response)
        });
};
