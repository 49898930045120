import React from 'react';
import '../../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { get, delet } from "../../network/Carousel";
import { withCookies } from "react-cookie";
import history from '../../../commons/History';
import { Message } from 'shineout'
//styles init
const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});
//Carousel list component 
class Carousels extends React.Component {
    constructor(props) {
        super(props);
        //state init
        this.state = {
            //table columns spacified here
            columns: [
                //{ title: 'Id', field: '_id', editable: 'never' },
                { title: 'Index', field: 'index', editable: 'never' },
                { title: 'Enabled', field: 'isEnabled', editable: 'never' },
                { title: 'Source', field: 'source', editable: 'never' },
                { title: 'Package', field: 'package', editable: 'never' },                                
                { title: 'TileName', field: 'tilename', editable: 'never' },                                
                { title: 'Target', field: 'target', editable: 'never'},
                
            ],
            data: [],
            token: this.props.cookies.cookies.token,
            username: this.props.cookies.cookies.username,
        };
        //get all carousels
        get(this.state.token, {}, (data) => {
            console.log(data)
            this.setState({ data: data });
        });
    }
    //render function
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* material table added here */}
                <MaterialTable
                    title="Carousel Banner list"
                    columns={this.state.columns}
                    data={this.state.data}
                    //on carousel row click show its details page
                    onRowClick={(event, rowData, toggleDetailPanel) => {
                        console.log(rowData)
                        history.push('/market/carousel', { data: rowData });
                    }}
                    //on add button click go to carousel datails page for new entry
                    actions={[{
                        icon: 'add',
                        tooltip: 'Create carousel',
                        isFreeAction: true,
                        onClick: () => {
                            history.push('/market/carousel', {});
                        }
                    }
                    ]}
                    //table options are here
                    options={{
                        actionsColumnIndex: 100,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100, 200],
                    }}
                    editable={{
                        //on carousel table row delete click handler function
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                //delete row with carousel's row data
                                delet(this.state.token, oldData._id, result => {
                                    //on delete success get all carouselsset state else show failed message
                                    if (result)
                                        get(this.state.token, {}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Carousel deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed  to delete carousel. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }),
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withCookies(Carousels));
