import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from "axios";
import LoadingDialog from "../commons/LoadingDialog";

// const API_URL = 'http://192.168.1.212:10002';
// const API_URL = '/market/v1';
const API_URL = '';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDone: false,
            loadingMessage: "Processing data, Please wait",
        };
        this.handleSubmit = this.handleSubmit.bind()
        this.handleFormChange = this.handleFormChange.bind()
        this.onLoadingDone = this.onLoadingDone.bind()
    }
    handleFormChange = name => event => {
        this.setState({ [name]: event.target.value, });
    };

    handleSubmit = event => {
        event.preventDefault();
        const data = {
            username: this.state.username,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
        };

        console.log(data);

        this.setState({ isLoading: true });
        this.setState({ isDone: false });
        this.setState({ loadingMessage: "Registering, Please wait" });

        const config = {
            headers:
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        };

        axios.post(API_URL + '/register', data, config)
            .then(response => {
                console.log(response);
                console.log('data: ' + JSON.stringify(response.data));
                console.log('STATUS: ' + JSON.stringify(response.data.status));
                if (response.status === 200) {
                    this.setState({ loadingMessage: 'Registration Done' });
                } else {
                    this.setState({ loadingMessage: 'Registration failed' });
                }
                this.setState({ isDone: true });
                this.setState({ response: true })
            })
            .catch(error => {
                this.setState({ loadingMessage: 'Registration failed' });
                this.setState({ isDone: true });
                console.log(error)
            })
    }

    onLoadingDone = event => {
        this.setState({ isLoading: false });
        this.props.onLogin(this.state.response);
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <LoadingDialog
                    open={this.state.isLoading}
                    status={this.state.isDone}
                    message={this.state.loadingMessage}
                    onDone={this.onLoadingDone} />
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Username"
                                    onChange={this.handleFormChange('username')}
                                    autoComplete="username"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    onChange={this.handleFormChange('name')}
                                    label="Full name"
                                    autoComplete="name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    onChange={this.handleFormChange('email')}
                                    label="Email Address"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    onChange={this.handleFormChange('password')}
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.handleSubmit}
                            className={classes.submit}
                        >
                            Sign Up
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link onClick={this.props.onLogin} variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={2} mb={2}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'All rights reserved at '}
                        <Link color="inherit" href="http://www.cloudwalker.tv">Cloudwalker Streaming Technologies, Mumbai</Link>
                    </Typography>
                </Box>
            </Container>
        );
    }
}
export default withStyles(styles)(Register);
