import React from 'react';
import '../../../../App.css';
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from 'material-table';
import { create, get, delet, update } from "../../network/App";
import { withCookies } from "react-cookie";
import history from '../../../commons/History';
import { Message } from 'shineout'

const styles = theme => ({
    theme: theme,
    root: {
        display: 'wrap',
    },
    container: {
        display: 'block',
        flexWrap: 'wrap',
    },
    fab: {
        margin: theme.spacing(1),
    },
    box: {
        component: 'div',
        display: 'inline',
        flexWrap: 'wrap',
    },
    card: {
        padding: theme.spacing(1),
        margin: theme.spacing(2),
    },
    textField: {
        margin: 8,
        padding: theme.spacing(1),
        width: 200,
    },
    media: {
        paddingTop: '56.25%',
    }
});
// App list component
class Apps extends React.Component {
    constructor(props) {
        super(props);
        //state init
        this.state = {
            //table columns spacified here
            columns: [
                { title: 'Title', field: 'title' },
                { title: 'Package', field: 'package' },
                { title: 'Index', field: 'index' },
            ],
            data: [],
            token: this.props.cookies.cookies.token,
            username: this.props.cookies.cookies.username,
        };
        //Getting all apps
        get(this.state.token, {}, (data) => {
            console.log(data)
            //setting state
            this.setState({ data: data });
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* Adding material table */}
                <MaterialTable
                    title="Apps"
                    columns={this.state.columns}
                    data={this.state.data}
                    //On table row click go to app detail page
                    onRowClick={(event, rowData, toggleDetailPanel) => {
                        console.log(rowData)
                        history.push('/market/app', { data: rowData });
                    }}
                    //table row rendering options
                    options={{
                        actionsColumnIndex: 100,
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100, 200],
                    }}
                    editable={{
                        //create new app the + button on top left of table click handler
                        onRowAdd: newData =>
                            new Promise(resolve => {
                                //create app with data
                                create(this.state.token, newData, (data) => {
                                    //on success geet updated app list & set state else show failed message
                                    if (data.success) {
                                        get(this.state.token, {}, (data) => {
                                            this.setState({ data: data });
                                            Message.success("App created", 3, {
                                                position: "bottom-right",
                                            });
                                            resolve();
                                        });
                                    } else {
                                        Message.error("Failed to create app. Try again", 3, {
                                            position: "bottom-right",
                                        });
                                        resolve();
                                    }
                                });
                            }),
                        //row spacific edit button on click handler
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                //update app data with old & new app data
                                update(this.state.token, oldData, newData, (status) => {
                                    //on status true get updated app list & set state else show failed message
                                    if (status) {
                                        get(this.state.token, {}, (data) => {
                                            this.setState({ data: data });
                                            Message.success("App Updated", 3, {
                                                position: "bottom-right",
                                            });
                                            resolve();
                                        });
                                    } else {
                                        Message.error("Failed to create app. Try again", 3, {
                                            position: "bottom-right",
                                        });
                                        resolve();
                                    }
                                });
                            }),
                        //row spacific delete button click handler
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                //delete app row using row data as oldData
                                delet(this.state.token, oldData.package, result => {
                                    //on delete success get updated applist else show failed message
                                    if (result)
                                        get(this.state.token, {}, (data) => {
                                            this.setState({ data: data });
                                            Message.success('Project deleted', 3, { position: 'bottom-right' });
                                            resolve();
                                        });
                                    else {
                                        Message.error('Failed deleting project. Try again', 3, { position: 'bottom-right' });
                                        resolve();
                                    }
                                })
                            }),
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withCookies(Apps));
