import React from 'react';

import { withCookies } from "react-cookie";

import { Form, Input, Rule, Card, Grid, Switch, Message, Upload } from 'shineout'
import { activateRelease } from '../../network/ReleaseActivation';
import { create_update } from '../../network/Release';

const HOST = '';
const UPLOAD_URL = HOST + '/upload';
// const UPLOAD_URL = HOST + '/upload';
const rules = Rule();
//Release creation & details component
class Release extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history.location.state.data);
        //state init
        this.state = {
            token: this.props.cookies.cookies.token,
            username: this.props.cookies.cookies.username,
            release_data: this.props.history.location.state.data,
            path: this.props.history.location.state.data.path ? [this.props.history.location.state.data.path] : [],
        };
        console.log(this.state)
    }

    componentWillMount() {
        //update release path
        if (this.state.release_data.path) {
            this.state.release_data.path = [this.state.release_data.path]
            this.setState({ release_data: this.state.release_data })
        } else {
            this.state.release_data.path = []
            this.setState({ release_data: this.state.release_data })
        }
    }
    //release rendering
    render() {
        return (
            <React.Fragment>
                <Card fullWidth>
                    {/* save button for release */}
                    <Card.Header>
                        Release Manager
                                <Card.Submit
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 120,
                            }}
                        >Submit</Card.Submit>

                    </Card.Header>
                    <Card.Body>
                        <Form
                            value={this.state.release_data}
                            //on form submit function
                            onSubmit={data => {
                                console.log(this.state.release_data)
                                this.state.release_data.path = data.path[0]
                                this.state.release_data.md5 = data.md5
                                this.state.release_data.whatsNew = data.whatsNew
                                this.state.release_data.isActivated = data.isActivated
                                this.state.release_data.keymd5 = data.keymd5
                                this.state.release_data.keysha256 = data.keysha256

                                console.log(this.state.release_data)
                                //create or update release call with release data
                                create_update(this.state.token, this.state.release_data, (result) => {
                                    if (result.success) {
                                        Message.success('Operation successful', 3, { position: 'bottom-right' });
                                        console.log(result)
                                        if (result._id)
                                            this.state.release_data._id = result._id
                                        this.setState({ release_data: this.state.release_data })
                                        if (data.isActivated) {
                                            activateRelease(this.state.token, this.state.release_data.package, this.state.release_data._id, result => {
                                                console.log(result)
                                                if (result) {
                                                    Message.success('Release activated', 3, { position: 'bottom-right' });
                                                } else {
                                                    Message.success('Failed activating release', 3, { position: 'bottom-right' });
                                                }
                                            })
                                        }
                                    } else {
                                        Message.error('Failed creating release. Try again', 3, { position: 'bottom-right' });
                                    }
                                });
                            }}>
                            <Grid gutter={2}>
                                <Grid width={1 / 1}>
                                    {/* release uploading component */}
                                    <Form.Item required label="Release application">
                                        <Upload
                                            action={UPLOAD_URL}
                                            accept="application/vnd.android.package-archive"
                                            name="path"
                                            headers={{ Authorization: 'Bearer ' + this.state.token }}
                                            params={{ packageName: this.state.release_data.package }}
                                            htmlName={'file'}
                                            limit={1}
                                            renderResult={f => f}
                                            onHttpError={xhr => {
                                                return JSON.parse(xhr.response).message
                                            }}
                                            onSuccess={(res, file, data) => {
                                                res = JSON.parse(res);
                                                console.log(res)
                                                //uploaded apk, updating state with release extracted value
                                                this.setState(oldstate => {
                                                    oldstate.release_data.permissions = res.permissions
                                                    oldstate.release_data.features = res.features
                                                    oldstate.release_data.screenLayout = res.screenLayout
                                                    oldstate.release_data.versionCode = res.versionCode
                                                    oldstate.release_data.versionName = res.versionName
                                                    oldstate.release_data.package = res.package
                                                    oldstate.release_data.maxSdkVersion = res.maxSdkVersion
                                                    oldstate.release_data.minSdkVersion = res.minSdkVersion
                                                    oldstate.release_data.targetSdkVersion = res.targetSdkVersion
                                                    oldstate.release_data.localization = res.localization.split(',')
                                                    oldstate.release_data.certificate = res.certificate
                                                    oldstate.release_data.size = res.size
                                                    oldstate.release_data.path = res.path
                                                    return oldstate;
                                                })
                                                return res.path;
                                            }}
                                            //upload file vaidation must be apk
                                            validator={
                                                {
                                                    ext: ext => (['apk'].includes(ext) ?
                                                        undefined :
                                                        new Error('File is not a valid apk file')),
                                                }
                                            }
                                        >
                                            <Upload.Dragger>
                                                <i className="material-icons">cloud_upload</i>
                                                <br />
                                                Click or Drag application to upload
                                                <br />
                                                Release application
                                            </Upload.Dragger>
                                        </Upload>
                                    </Form.Item>
                                </Grid>
                                <Grid width={1 / 2}>
                                    <Form.Item required label="MD5">
                                        <Input
                                            name="md5"
                                            rules={[rules.required, rules.isExist]} />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1 / 2}>
                                    <Form.Item required label="Whats new">
                                        <Input
                                            name="whatsNew"
                                            rules={[rules.required, rules.isExist]} />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1 / 2}>
                                    <Form.Item required label="Activation state">
                                        <Switch name="isActivated" />
                                    </Form.Item>
                                </Grid>
                                {/*<Grid width={1 / 2}>
                                    <Form.Item required label="KeyMD5">
                                        <Input
                                            name="keymd5"
                                            rules={[rules.isExist]} />
                                    </Form.Item>
                                </Grid>
                                <Grid width={1 / 2}>
                                    <Form.Item required label="KeySha256">
                                        <Input
                                            name="keysha256"
                                            rules={[rules.isExist]} />
                                    </Form.Item>
                                </Grid>*/}
                            </Grid>
                        </Form>
                    </Card.Body>
                </Card>
            </React.Fragment >
        )
    }
}

export default withCookies(Release);
