import superagent from 'superagent';

const URL = '/features';

//get lms_feature list request
export const get = (token, query, callback) => {
    console.log('******LMS FEATURES TO GET ARRAY********')
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
            console.log(response);
        }
        );
};


/*
//get release data request
export const getfeature = (token, query, callback) => {
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
        });
};
*/
