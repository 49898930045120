import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
//styles init
const styles = theme => ({
    root: {
        maxWidth: 300,
        display: 'block',
        flexWrap: 'wrap',
    },
});
//Loading dialog component
class LoadingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind();
    }
    //close dialog handler
    handleClose = event => {
        this.props.onDone(event);
    }
    //render function
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* adding dialog here */}
                <Dialog
                    open={this.props.open}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="slide-title"
                    aria-describedby="slide-description">
                    <DialogTitle id="uploading-dialog">{this.props.message}</DialogTitle>
                    <DialogActions>
                        {(this.props.status) ? <Button onClick={this.handleClose} color="primary">
                            Done
                        </Button> :
                            <DialogContent>
                                <LinearProgress />
                            </DialogContent>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(LoadingDialog);
