import superagent from 'superagent';

const URL = '/content_rules';
//create content rule request
export const create = (token, rule_body, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ rule_body: rule_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true, _id: response.body._id })
            } else {
                callback({ success: false })
            }
            console.log(JSON.stringify(response))
        });
};
//update content rule request
export const update = (token, rule_body, callback) => {
    superagent.put(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ rule_body: rule_body })
        .end((err, response) => {
            if (response.status == 200) {
                callback(true)
            } else {
                callback(false)
            }
            console.log(JSON.stringify(response))
        });
};
//get content rule request
export const get = (token, query, callback) => {
    superagent.get(URL)
        .set('Authorization', 'Bearer ' + token)
        .query(query)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback([]);
                console.log('No data available')
            }
            console.log(response);
        }
        );
};
//delete content rule request
export const delet = (token, _id, callback) => {
    superagent.delete(URL + '/' + _id)
        .set('Authorization', 'Bearer ' + token)
        .end((err, response) => {
            if (response.status == 200) {
                callback(true)
            } else {
                callback(false)
            }
            console.log(JSON.stringify(response))
        });
};
