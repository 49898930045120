import superagent from 'superagent';

const URL = '/activation';
//Activate release request
export const activateRelease = (token, packageName, _id, callback) => {
    superagent.post(URL)
        .set('Authorization', 'Bearer ' + token)
        .send({ package: packageName, _id: _id })
        .end((err, response) => {
            if (response.status == 200) {
                callback({ success: true })
            } else {
                callback({ success: false })
            }
            console.log(JSON.stringify(response))
        });
};
//get release data request
export const get = (token, packageName, callback) => {
    superagent.get(URL + '/' + packageName)
        .set('Authorization', 'Bearer ' + token)
        .end((err, response) => {
            if (response.status == 200) {
                callback(response.body);
            } else {
                callback(null);
                console.log('No data available')
            }
            console.log(response);
        });
};
